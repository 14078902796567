import React, { useState, useEffect, useCallback, useContext, useMemo } from 'react';
import './styles.css';
import PropTypes from 'prop-types';
import Dropdown from '../Dropdown';
import { useDispatch } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Route } from 'react-router-dom';
import { addEvent } from '../../store/actions/dataLayer';
import { searchTypes, keywordSearchPattern } from '../../constants/boats';
import { PRODUCT_CATEGORY, TRACKING_EVENTS } from '../../constants/dataLayer';
import { generateSearchPath as generateSearchBoatsPath } from '../../utils/urlHelpers/boats';
import { generateSearchPath as generateSearchEnginesPath } from '../../utils/urlHelpers/engines';
import { getBreakpoint, BREAKPOINTS } from '../../utils/commonHelper';
import { getMessages } from '../../tppServices/translations/messages';
import { PortalConfigContext } from '../../config/portal';
import { yieldToMain } from '../../utils';
import { OptionSet, Option } from '../OptionSet';
import debounce from 'lodash/debounce';

const validationRegExp = RegExp(keywordSearchPattern);

const executeEvent = async (event, data, dispatch) => {
  await yieldToMain();
  dispatch(addEvent(event, data));
};

const KeywordSearch = ({ intl: { formatMessage: t }, keywordSearchOptions, isWorking }) => {
  const dispatch = useDispatch();
  const context = useContext(PortalConfigContext);

  const [inputValue, setInputValue] = useState('');
  const [searchType, setSearchType] = useState([]);
  const [breakpoint, setBreakpoint] = useState(() => 'desktop');
  const [isPageLoaded, setIsPageLoaded] = useState(false);

  const keywordSearchOpt = keywordSearchOptions?.paramFormat;
  const requiresReload = Boolean(keywordSearchOptions?.requiresReload);

  const resizeHandler = useCallback(() => setBreakpoint(getBreakpoint()), []);

  useEffect(() => {
    window.addEventListener('resize', resizeHandler);
    return () => window.removeEventListener('resize', resizeHandler);
  }, [resizeHandler]);

  useEffect(() => {
    const handlePageLoad = () => {
      setIsPageLoaded(true);
      document.getElementById('id-keyword-button')?.classList.remove('disable-actions');
    };
    document.readyState === 'complete' ? handlePageLoad() : window.addEventListener('load', handlePageLoad);

    return () => {
      window.removeEventListener('load', handlePageLoad);
    };
  }, [isPageLoaded]);

  const supportsFeature = useCallback(feature => context.supports[feature], [context]);

  const validateKeyword = useCallback(word => !word.length || validationRegExp.test(word), []);

  const handleInputFocus = () => document === undefined || document.querySelector('.keyword-input').focus();

  const handleInputChange = useCallback(e => {
    const searchKeyword = e.target.value;
    if (validateKeyword(searchKeyword)) {
      setInputValue(searchKeyword);
    }
  }, [validateKeyword]);

  const getParams = useCallback(() => ({
    keyword: inputValue,
    searchType,
  }), [inputValue, searchType]);

  const handleSubmit = useCallback(async (e, history) => {
    e.preventDefault();

    if (!inputValue.trim()) {
      return;
    }
    setInputValue('');

    const params = getParams();
    const selectedSearchType = params.searchType[0]?.value;

    const eventCategory = selectedSearchType === searchTypes.engines && supportsFeature(searchTypes.engines)
      ? PRODUCT_CATEGORY.ENGINE
      : PRODUCT_CATEGORY.BOAT;

    executeEvent(TRACKING_EVENTS.KEYWORD_SEARCH, {
      keyword: params.keyword.trim().toLowerCase(),
      'product_category': eventCategory,
    }, dispatch);

    params.searchKeywordParamFormat = keywordSearchOpt;
    const path = selectedSearchType === searchTypes.engines && supportsFeature(searchTypes.engines)
      ? generateSearchEnginesPath(params)
      : generateSearchBoatsPath(params);
    let currentPath = keywordSearchOpt === 'query-string' ? path.replace('%3F', '?') : path;

    requiresReload ? window.location.assign(currentPath) : history.push(currentPath);
  }, [inputValue]);

  const handleSelectChange = useCallback(value => {
    setSearchType(value);
    handleInputFocus();
  }, []);

  const debouncedHandleSubmit = useCallback(debounce(handleSubmit, 300), [handleSubmit]);

  const keywordButtonClick = useCallback((history) => {
    if (supportsFeature('enableTopNavigationV2') && breakpoint === BREAKPOINTS.desktop) {
      return (e) => debouncedHandleSubmit(e, history);
    }
    return handleInputFocus;
  }, [breakpoint, debouncedHandleSubmit, supportsFeature]);

  const messages = getMessages();
  const searchTypesArray = useMemo(() => {
    const types = [{ value: searchTypes.boats, label: t(messages.searchTypeBoats) }];
    if (supportsFeature(searchTypes.engines)) {
      types.push({ value: searchTypes.engines, label: t(messages.searchTypeEngines) });
    }
    return types;
  }, [messages, supportsFeature, t]);

  return (
    <Route render={({ history }) => (
      <div className="keyword-search">
        <button
          id="id-keyword-button"
          className="keyword-button disable-actions"
          onClick={keywordButtonClick(history)}
          aria-label="Search"
          disabled={!isPageLoaded}
        />
        <form onSubmit={(e) => {
            e.preventDefault();
            debouncedHandleSubmit(e, history);
          }}
          disabled={isWorking}>
          <input
            className="keyword-input"
            placeholder={t(messages.header.keywordSearch)}
            value={inputValue}
            onChange={handleInputChange}
            aria-label="Keyword Search"
            type="search"
            enterKeyHint="search"
            disabled={isWorking}
          />
          {supportsFeature('enableTopNavigationV2') && (
            <button
              aria-label="Reset"
              type="reset"
              className="keyword-reset"
              onClick={() => setInputValue('')}
              disabled={isWorking}
            />
          )}
        </form>
        {supportsFeature(searchTypes.engines) && (
          <Dropdown
            label={t(messages.header.keywordSearch)}
            setSize={true}
            multiple={false}
            setValue={handleSelectChange}
            options={searchTypesArray}
            optionKey="label"
            value={searchType.length ? searchType : [searchTypesArray[0]]}
            render={(options, inheritProps) => (
              <OptionSet {...inheritProps}>
                {options.map((option, idx) => (
                  <Option key={`searchType-${idx}`} option={option} {...inheritProps}>
                    {option.label}
                  </Option>
                ))}
              </OptionSet>
            )}
          />
        )}
      </div>
    )} />
  );
};

KeywordSearch.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  keywordSearchOptions: PropTypes.object,
  isWorking: PropTypes.bool,
};

export default injectIntl(KeywordSearch);
